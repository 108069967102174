import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Loader from "../Loader";
import API_BASE_URL from "../utils";

interface ProductType {
  id: number;
  section: string;
  link: string[];
}

interface Social {
  imgsrc: string;
  href: string;
}

const socialLinks: Social[] = [
  { imgsrc: "/images/Footer/insta.svg", href: "https://instagram.com/" },
  { imgsrc: "/images/Footer/dribble.svg", href: "https://dribble.com/" },
  { imgsrc: "/images/Footer/twitter.svg", href: "https://twitter.com/" },
  { imgsrc: "/images/Footer/youtube.svg", href: "https://youtube.com/" },
];

const Footer: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);

  const blockedDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "aol.com",
    "icloud.com",
    "rediffmail.com",
    "protonmail.com",
    "live.com",
  ];

  const isCompanyEmail = (email: string) => {
    const domain = email.split("@")[1]?.toLowerCase();
    if (!domain) return false;
    return !blockedDomains.includes(domain);
  };

  const handleSubscribe = async () => {
    setLoader(true);
    setError("");
    setMessage("");

    if (!email || !email.includes("@") || !email.includes(".")) {
      setError("Please enter a valid email address.");
      setLoader(false);
      return;
    }

    if (!isCompanyEmail(email)) {
      setError("Please use a company email address.");
      setLoader(false);
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}addToWaitlist`, {
        email,
      });

      if (response.data.message === "Already subscribed") {
        setError("This email is already subscribed.");
        setMessage("");
      } else {
        setMessage("Your email has been added to our waiting list.");
        setEmail("");
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
    } finally {
      setLoader(false);
    }
  };

  return (
    <div
      className="relative"
      style={{
        background:
          "linear-gradient(45deg, #0C0B27, #1B164F, #1C1A58, #10184B, #091239, #020A1E)",
      }}
    >
      {loader && <Loader />}
      <div className="radial-bg hidden lg:block"></div>
      <div className="mx-auto max-w-2xl mt-2 pb-16 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="flex flex-col lg:flex-row gap-y-10 lg:gap-x-16">
          {/* COLUMN-1 */}
          <div className="lg:col-span-6 mt-6">
            <img
              className="block h-12 mb-4"
              src="/images/Logo/&telligence_dark.png"
              alt="Logo"
            />
            <h3 className="text-lightblue text-sm font-normal lg:leading-9 mb-4">
              Andtelligence is a Microsoft for Startup and Startup India
              recognised company building cutting edge AI solutions to
              streamline technical documentation, regulatory compliance, market
              research, and operational efficiency for Aerospace and Defence
              industry.
            </h3>
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center w-full">
              <div className="mb-4 text-lightblue lh:font-medium text-base lg:text-lg w-full lg:w-2/4">
                <p>Subscribe to our newsletter</p>
                <div className="flex flex-col lg:flex-row">
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter your email"
                    variant="outlined"
                    value={email}
                    size="small"
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    sx={{
                      background: "white",
                      borderRadius: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="warning"
                    onClick={handleSubscribe}
                    disabled={loader}
                    sx={{ marginTop: { xs: "10px", lg: "0" } }}
                  >
                    {loader ? "Subscribing..." : "Subscribe"}
                  </Button>
                </div>
                <div className="flex">
                  {error && (
                    <p className="text-center text-[#FF0000] text-base font-normal mt-2">
                      {error}
                    </p>
                  )}
                  {message && (
                    <p className="text-center text-green text-base font-normal mt-2">
                      {message}
                    </p>
                  )}
                </div>
              </div>
              <div className="w-full lg:w-2/5 flex flex-col justify-center items-center mt-1 lg:mt-0">
                <img
                  src="/images/startupindia.png"
                  alt="number-icon"
                  width={200}
                  height={200}
                />
                <p className="text-offwhite">Reg No: 6756GGTA442</p>
              </div>
            </div>
            <div className="flex gap-4 items-center mt-2 lg:mt-6 justify-center md:justify-start ">
              {socialLinks.map((items, i) => (
                <a
                  href={items.href}
                  key={i}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={items.imgsrc}
                    alt={items.imgsrc}
                    className="footer-icons"
                  />
                </a>
              ))}
            </div>
          </div>

          {/* COLUMN-2 */}
          <div className="lg:col-span-4 lg:mt-6">
            <h3 className="text-white text-xl font-medium mb-2 lg:mb-9">Contact Us</h3>
            <h4 className="text-offwhite text-sm font-normal mb-2 lg:mb-6 flex gap-2">
              <img
                src="/images/Footer/email.svg"
                alt="email-icon"
                width={20}
                height={20}
              />
              contact@andtelligence.com
            </h4>
            <h4 className="text-offwhite text-sm font-normal mb-2 lg:mb-6 flex gap-2">
              <img
                src="/images/Footer/address.svg"
                alt="address-icon"
                width={20}
                height={20}
              />
              Gurugram, India
            </h4>
          </div>
        </div>
      </div>

      {/* All Rights Reserved */}
      <div className="py-8 px-4 border-t border-t-lightblue">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 flex flex-col lg:flex-row justify-between items-center">
          <h3 className="text-center text-offwhite opacity-75 mb-4 lg:mb-0">
            @2024 - All Rights Reserved by &telligence.com
          </h3>
          <a
            href="https://madewithloveinindia.org"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white opacity-75"
          >
            Made with{" "}
            <span aria-label="Love" style={{ color: "#f43f5e" }}>
              &hearts;
            </span>{" "}
            in India
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
