import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import axios from "axios";
import RootLayout from "../layout";
import API_BASE_URL from "../utils";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";

const Categories: React.FC = () => {
  const { category_id } = useParams<{ category_id: string }>();

  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (category_id) {
      axios
        .get(`${API_BASE_URL}getDocumentByCategory?category_id=${category_id}`)
        .then((response) => {
          setData(response.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching data:", err);
          setError("Failed to fetch data.");
          setLoading(false);
        });
    }
  }, [category_id]);

  const navigate = useNavigate();

  const handleRedirect = (queryName: string, documentId: number) => {
    navigate(`/article/${queryName}/${documentId}`);
  };
  return (
    <>
      <div className="relative flex justify-center items-center mt-8 bg-offwhite">
        {loading && <Loader />}
        <div
          className="absolute inset-0 w-full h-full bg-cover bg-center opacity-70"
          style={{ backgroundImage: `url('${data?.category_image}')` }}
        ></div>
        <div
          className="w-full max-w-7xl mx-auto flex justify-center items-center h-60 bg-cover bg-center relative"
          style={{ backgroundImage: `url('${data?.category_image}')` }}
        >
          <p className="text-black font-medium text-2xl bg-white bg-opacity-80 px-4 py-2 rounded-full z-10">
            {data?.category}
          </p>
        </div>
      </div>

      <div className="relative w-full max-w-7xl mx-auto mt-20 px-6 min-h-[70vh]">
        <Grid container spacing={4} justifyContent="center">
          {data?.document?.map((value: any) => (
            <>
              {/* Visible only on small and medium screens */}
              <Grid
                item
                xs={12}
                lg="auto"
                sx={{ display: { xs: "block", lg: "none" } }}
                key={value?.document_id}
                md={6}
              >
                <div
                  onClick={() =>
                    handleRedirect(value?.query_name, value?.document_id)
                  }
                  className="flex flex-col cursor-pointer items-center bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 w-full h-auto"
                >
                  <img
                    className="object-cover w-full h-36 md:h-auto rounded-t-lg md:rounded-none md:rounded-s-lg"
                    src={value?.image}
                    alt="Document Image"
                  />
                  <div className="flex flex-col justify-between p-4 leading-normal w-full">
                    <h5 className="text-base font-bold tracking-tight text-gray-900">
                      {value?.heading_2}
                    </h5>
                    <p className="text-xs font-normal text-gray-700">
                      {value?.heading_3.length > 120
                        ? `${value.heading_3.slice(0, 120)}...`
                        : value.heading_3}
                    </p>
                    <div className="mt-2 flex items-center">
                      <p className="text-xs font-medium text-[#CF2318] text-opacity-65">
                        {"Read More >>"}
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>

              {/* Visible only on large screens and above */}
              <Grid
                item
                xs={12}
                md={6}
                key={value?.document_id}
                sx={{ display: { xs: "none", lg: "block" } }}
                lg="auto"
              >
                <div
                  onClick={() =>
                    handleRedirect(value?.query_name, value?.document_id)
                  }
                  className="flex cursor-pointer flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 h-52"
                >
                  <img
                    className="object-cover w-full rounded-t-lg h-full md:h-full md:w-48 md:rounded-none md:rounded-s-lg"
                    src={value?.image}
                    alt="Document Image"
                  />
                  <div className="flex flex-col justify-between p-4 leading-normal h-full">
                    <h5 className="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-black">
                      {value?.heading_2}
                    </h5>
                    <p className="mb-3 font-normal text-gray-700 dark:text-black text-base">
                      {value?.heading_3.length > 100
                        ? `${value.heading_3.slice(0, 100)}...`
                        : value.heading_3}
                    </p>
                    <div className="flex cursor-pointer items-center">
                      <p className="text-sm font-medium text-[#CF2318] text-opacity-75">
                        {"Read More >>"}
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default Categories;
