// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Banner from './components/Banner';
import Companies from './components/Companies';
import Work from './components/Work';
import Table from './components/Table';
import Features from './components/Features';
import Simple from './components/Simple';
import Trade from './components/Trade';
import Faq from './components/Faq';
import TestimonialSlider from './components/Testimonials/TestimonialSlider';
import CookieNotice from './components/CookieNotice';
import RootLayout from './components/layout';
import Categories from './components/Articles/Categories';
import DetailedArticle from './components/Articles/DetailedArticle';
import ContactForm from './components/Contact';
import AboutUs from './components/About';

function App() {
  return (
    <Router>
      <RootLayout>
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <CookieNotice />
                <Banner />
                <Work />
                <Table />
                <Features />
                <Trade />
                <Simple />
                <TestimonialSlider />
              </>
            } 
          />

          {/* Future Route Examples */}
          {/* <Route path="/faq" element={<Faq />} />
          <Route path="/companies" element={<Companies />} /> */}
          {/* Add more routes here as needed */}
          <Route path="/category/:category_id" element={<Categories />} />
          <Route path="/article/:filename/:document_id" element={<DetailedArticle />} />
          <Route path="/contact-us" element={<ContactForm />} />
          <Route path="/about-us" element={<AboutUs />} />

        </Routes>
      </RootLayout>
    </Router>
  );
}

export default App;
