import React, { useState, useEffect } from "react";

interface Testimonial {
  id: number;
  text: string;
  position: string;
  company: string;
  image: string;
}

const TestimonialSlider: React.FC = () => {
  const items: Testimonial[] = [
    {
      id: 1,
      text: "I am quite buoyant about “&telligence” becoming the standard bearer for Aerospace & Defence Sector.",
      position: "Associate Director",
      company: "Boston Consulting Group",
      image: "/images/bcg.png",
    },
    {
      id: 2,
      text: "&telligence has provided consulting and custom research services to our firm. Can vouch for the quality of his work.",
      position: "Associate Director - Industrials and Defence Sector",
      company: "Equirus Capital",
      image: "/images/equaris.png",
    },
    {
      id: 3,
      text: "&telligence Technologies has been a valuable partner for MGF - Kavachh, providing market research and advisory services that have helped refine our investment strategy. Their insights, especially in complex, data-scarce environments, have been invaluable in our due diligence efforts.",
      position: "Managing Partner",
      company: "Mounttech Growth Fund-Kavachh",
      image: "/images/kavachh.png",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const { text, position, company, image } = items[currentIndex];

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!isHovered) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
      }, 3000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isHovered]);

  return (
    <div
      className="relative w-full max-w-7xl mx-auto my-20 px-6 flex-col flex justify-center items-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="text-center mb-10">
        <h3 className="text-offwhite text-2xl md:text-4xl font-bold mb-3">
          Hear From Our Clients
        </h3>
      </div>

      <section
        className="rounded-lg lg:h-60 h-72 flex justify-center items-center"
        style={{ background: "linear-gradient(#1a2c66, #131c44)" }}
      >
        <div className="max-w-screen-xl px-4 py-4 mx-auto text-center lg:px-6">
          <figure className="max-w-screen-md mx-auto">
            <blockquote>
              <p className="text-base font-medium text-white">{text}</p>
            </blockquote>
            <figcaption className="flex items-center justify-center mt-2 space-x-3">
              <img className="w-12" src={image} alt="company logo" />
              <div className="flex items-center divide-x-2 divide-gray-700">
                <div className="pr-3 text-xs font-medium text-white">
                  {position}
                </div>
                <div className="pl-3 text-xs font-light text-gray-400">
                  {company}
                </div>
              </div>
            </figcaption>
          </figure>
        </div>
      </section>
    </div>
  );
};

export default TestimonialSlider;
