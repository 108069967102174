"use client";
import React from "react";

const Banner = () => {
  return (
    <div id="home-section" className="relative">
      {/* Background Video */}
      <div className="w-full h-full">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute top-0 left-0 w-full h-full object-cover -z-10"
        >
          <source src="/ai.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-60 -z-10"></div>
      </div>

      {/* Radial banner overlay */}
      <div className="radial-banner hidden lg:block"></div>

      {/* Content */}
      <div className="mx-auto max-w-7xl pt-5 lg:pt-28 sm:pb-24 px-6">
        <div className="height-work">
          <div className="grid grid-cols-1 lg:grid-cols-12 my-16">
            <div className="col-span-7">
              <h1 className="text-4xl lg:text-5xl font-bold mb-5 text-white md:4px md:text-start text-center">
                Aerospace and Defence market intelligence <br /> powered by AI
              </h1>
              <p className="text-white md:text-lg font-normal mb-10 md:text-start text-center">
                At &telligence, we merge tech expertise with strategic
                consultancy to deliver unmatched, reliable and assured Aerospace
                and Defence market intelligence in days, not months.
              </p>
              <div className="flex align-middle justify-center md:justify-start">
                <button className="lg:text-xl text-sm font-semibold text-white py-4 px-6 navbutton mr-6">
                  Get Started
                </button>
                <button className="lg:text-xl text-sm font-semibold text-white py-4 px-6 border-2 border-white rounded-[10px] bg-transparent">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
