const Trade = () => {
  return (
    <div className="mx-auto max-w-7xl mt-48 mb-16 px-6 relative">
      <div className="radial-bgone hidden lg:block"></div>

      <div className="grid lg:grid-cols-2 gap-x-5 justify-center items-center">
        {/* Column-1 */}
        <div>
          <img
            src={"/images/info.png"} // Adjust path based on your public folder structure
            alt="A visual representation of technology and data analysis"
            width={787}
            height={512}
            className="object-cover" // Optional: to ensure the image covers its container
          />
        </div>

        {/* Column-2 */}
        <div>
          <h3 className="text-3xl font-semibold text-offwhite mb-6 text-center sm:text-start">
            Technology is at the
            <br /> heart of what we do
          </h3>
          <p className="lg:text-lg font-normal text-lightblue mb-16 text-center sm:text-start">
            We harness the capabilities of AI and NLP to transform vast amounts
            of data into actionable insights to provide on-demand, reliable, and
            contextual market intelligence and generate and share ready-to-use
            reports.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Trade;
