// import './globals.css';
// import '@/node_modules/react-modal-video/scss/modal-video.scss';
import Navbar from "../components/Navbar/index";
import Footer from "../components/Footer/index";

export const metadata = {
  title: "&telligence",
  description: "Generated by create next app",
  icons: {
    icon: "/logo.svg",
  },
};

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
}
