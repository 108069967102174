import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  useMediaQuery,
} from "@mui/material";
import ShadowRoot from "react-shadow-root";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import API_BASE_URL from "../utils";
import Loader from "../Loader";

const DetailedArticle: React.FC = () => {
  const isLargeScreen = useMediaQuery("(min-width: 1024px)");
  const { filename, document_id } = useParams<{
    filename: string;
    document_id: string;
  }>();

  const [htmlContent, setHtmlContent] = useState<string | null>(null);
  const [documentData, setDocumentData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSummary, setSummary] = useState(true);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
  });
  const [formData, setFormData] = useState({
    clientName: "",
    company: "",
    jobTitle: "",
    email: "",
    phoneNumber: "",
    query: "",
  });

  const fetchDocumentData = async (filename: any, document_id: any | any[]) => {
    try {
      const response = await axios.get(`${API_BASE_URL}getDocumentById`, {
        params: {
          query_name: filename,
          document_id: document_id,
        },
      });
      return response?.data?.data;
    } catch (error) {
      console.error("Error fetching document data:", error);
      throw new Error("Failed to fetch document data");
    }
  };

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOpen = (title: string, description: string) => {
    setModalContent({ title, description });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getData = async () => {
      if (filename && document_id) {
        try {
          const data = await fetchDocumentData(filename, document_id);
          setDocumentData(data);

          if (data?.document_link) {
            const response = await axios.get(data.document_link, {
              responseType: "text",
            });
            setHtmlContent(response.data);
          } else {
            throw new Error("Document link not found");
          }
        } catch (error) {
          setError("Something went wrong");
        } finally {
          setLoading(false);
        }
      }
    };

    getData();
  }, [filename, document_id]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const sendEmail = async (button: string) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}theEmailSender`, {
        button: button,
        clientName: formData.clientName,
        company: formData.company,
        jobTitle: formData.jobTitle,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        reportName: documentData?.sub_category,
        reportSegment: documentData?.heading_1,
        inquiryText: formData.query,
      });

      // Handle response if needed
      handleClose();
      setLoading(false);
    } catch (error) {
      console.error("Error sending email:", error);
      handleClose();
      setLoading(false);
      throw error; // Re-throw the error for handling in the calling component
    }
  };

  return (
    <>
      <div className="bg-white">
        {loading && <Loader />}
        <div className="">
          <div className="mx-auto max-w-7xl px-6">
            <div className="pt-4">
              <p className="text-sm lg:text-2xl">{documentData?.heading_1}</p>
              <p className="text-base font-medium lg:text-3xl pt-2 lg:pt-5">
                {documentData?.heading_2}
              </p>
              <p className="text-sm lg:text-lg pt-1">
                {documentData?.heading_3}
              </p>
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 gap-4 my-2 lg:my-4">
              <div className="flex flex-col">
                <p className="font-semibold text-sm lg:text-base text-navyblue">
                  Base Year
                </p>
                <p className="font-normal text-sm lg:text-base">
                  {documentData?.base_year}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="font-semibold text-sm lg:text-base text-navyblue">
                  Region
                </p>
                <p className="font-normal text-sm lg:text-base">
                  {documentData?.region}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="font-semibold text-sm lg:text-base text-navyblue">
                  Research Code
                </p>
                <p className="font-normal text-sm lg:text-base">
                  DOC-00{documentData?.document_id}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="font-semibold text-sm lg:text-base text-navyblue">
                  Status
                </p>
                <p className="font-normal text-sm lg:text-base">
                  {documentData?.status}
                </p>
              </div>
            </div>
          </div>

          <div
            className="flex justify-center"
            style={{
              backgroundImage: `url('/images/abg.jpg')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: "100%",
              // height: "100vh",
            }}
          >
            <div className="max-w-7xl px-6 mt-4 w-full">
              <div className="border-b-[3px] border-black flex justify-between w-full lg:w-[75%] mb-4">
                <div className="mb-4 gap-4 justify-between flex w-full lg:justify-start">
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: isSummary ? "#1A2B6D" : "#ccc",
                      marginRight: "50px",
                    }}
                    size={isLargeScreen ? "medium" : "small"}
                    onClick={() => setSummary(true)}
                  >
                    Summary
                  </Button>
                  {/* <Button
                    variant="contained"
                    style={{
                      backgroundColor: !isSummary ? "#1A2B6D" : "#ccc",
                    }}
                    size={isLargeScreen ? "medium" : "small"}
                    onClick={() => setSummary(false)}
                  >
                    TOC
                  </Button> */}
                </div>
                <div className="hidden lg:block">
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#1A2B6D",
                      whiteSpace: "nowrap", // Prevents text from wrapping
                      minWidth: "180px",
                    }}
                    size={isLargeScreen ? "medium" : "small"}
                    onClick={() =>
                      handleOpen(
                        "Request Sample",
                        "Our team will swiftly act on your request and we will send you the quote within 24 hours. If there are specific customisations required please click the Inquire Now button or Speak to our analyst."
                      )
                    }
                  >
                    Request Sample
                  </Button>
                </div>
              </div>

              <Grid container>
                <Grid item xs={12} md={9}>
                  <Box sx={{ width: "100%", overflow: "auto" }}>
                    {isSummary && (
                      <div className="h-[180vh] overflow-auto rounded-md border border-x-blue mb-4 p-4 bg-white">
                        {loading && <p>Loading...</p>}
                        {error && <p>{error}</p>}
                        {htmlContent && (
                          <ShadowRoot>
                            <div
                              className="htmlContent"
                              dangerouslySetInnerHTML={{ __html: htmlContent }}
                            />
                            <style>
                              {`
                          .htmlContent {
                            font-family: Arial, sans-serif;
                          }

                          .htmlContent h1 {
                            font-size: 2em; /* Ensure this is the size you want */
                            font-weight: bold;
                            text-decoration: underline;
                          }

                          .htmlContent h2,
                          .htmlContent h3,
                          .htmlContent p {
                            margin: 0;
                            padding: 0;
                          }

                          .htmlContent p {
                            margin-bottom: 1em;
                          }

                          .htmlContent ul,
                          .htmlContent ol {
                            margin: 1em 0;
                            padding-left: 1.5em;
                          }

                          .htmlContent ul {
                            list-style-type: disc;
                          }

                          .htmlContent ol {
                            list-style-type: decimal;
                          }

                          .htmlContent table {
                            border-collapse: collapse;
                            width: 100%;
                          }

                          .htmlContent th,
                          .htmlContent td {
                            border: 1px solid #000;
                            padding: 8px;
                          }
                        `}
                            </style>
                          </ShadowRoot>
                        )}
                      </div>
                    )}
                    {!isSummary && (
                      <div className="rounded overflow-hidden shadow-lg border border-offwhite bg-white mt-4">
                        <div className="p-3">
                          <p className="font-semibold text-2xl text-[#1C77C3] mb-4">
                            TABLE OF CONTENT
                          </p>

                          <div
                            className="mb-2 font-medium border-b cursor-pointer flex items-center"
                            onClick={toggleOpen}
                          >
                            {/* Icon at the beginning */}
                            <div className="mr-2">
                              {isOpen ? (
                                <ExpandMore style={{ fontSize: 40 }} />
                              ) : (
                                <ChevronRightIcon style={{ fontSize: 40 }} />
                              )}
                            </div>
                            <div className="flex justify-between items-center w-full">
                              <p className="p-4 text-xl">
                                1. Benefits of Blogging
                              </p>
                              <p className="p-4 text-xl font-bold">3</p>
                            </div>
                          </div>
                          {isOpen && (
                            <ul className="pl-20 list-disc text-lg">
                              <li className="mb-2">100% accurate reports</li>
                              <li className="mb-2">Best in industry</li>
                              <li className="mb-2">Experts on call</li>
                            </ul>
                          )}

                          <p className="mb-2 border-b cursor-pointer p-4 text-xl">
                            2. Rank on search engines
                          </p>
                          <p className="mb-2 border-b cursor-pointer p-4 text-xl">
                            3. Educate your leads and customers
                          </p>
                          <p className="mb-2 border-b cursor-pointer p-4 text-xl">
                            4. Defense Research
                          </p>
                          <p className="mb-2 border-b cursor-pointer p-4 text-xl">
                            5. Convert leads and prospects
                          </p>
                          <p className="mb-2 border-b cursor-pointer p-4 text-xl">
                            6. Become known as an expert
                          </p>
                        </div>
                      </div>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      marginLeft: isLargeScreen ? "10px" : "",
                    }}
                  >
                    <div className="flex flex-row justify-around lg:justify-between items-center w-full">
                      <div className="">
                        <img
                          src="/images/notepad.png"
                          alt="notpad graphic"
                          width={isLargeScreen ? 85 : 60}
                          height={isLargeScreen ? 85 : 60}
                        />
                      </div>
                      <div className="justify-center items-center flex flex-col ">
                        <button
                          type="button"
                          onClick={() =>
                            handleOpen(
                              "Request for Quote",
                              "Our team will swiftly act on your request and we will send you the quote within 24 hours. If there are specific customisations required please click the Inquire Now button or Speak to our analyst."
                            )
                          }
                          className="text-white w-full bg-blue-700 hover:bg-navyblue focus:ring-4
                      font-medium rounded-lg text-xs lg:text-sm px-2 lg:px-5 py-3 lg:me-2 mb-1 lg:mb-2 bg-[#1A2B6D] focus:outline-none"
                        >
                          Get a quote
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            handleOpen(
                              "Inquire Now",
                              "Thank you for your interest in our services. Please provide your details, and we will get in touch soon."
                            )
                          }
                          className="text-white w-full bg-blue-700 hover:bg-navyblue focus:ring-4
                      font-medium rounded-lg text-xs lg:text-sm px-2 lg:px-5 py-3 lg:me-2 mb-1 lg:mb-2 bg-[#1A2B6D] focus:outline-none
                      "
                        >
                          Inquire now
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            handleOpen(
                              "Speak To An Analyst",
                              "Thank you for your interest in our services. Please provide your details, and we will get in touch soon."
                            )
                          }
                          className="text-white w-full bg-blue-700 hover:bg-navyblue focus:ring-4
                      font-medium rounded-lg text-xs lg:text-sm px-2 lg:px-5 py-3 lg:me-2 mb-1 lg:mb-2 bg-[#1A2B6D] focus:outline-none
                      "
                        >
                          Speak to an analyst
                        </button>
                      </div>
                    </div>
                    {/* disabled for articles */}
                    {/* 
                    <div className="rounded overflow-hidden shadow-lg border border-offwhite bg-[#F5F5F5] mb-4">
                      <div className="p-5">
                        <div className="flex justify-between items-center mb-4">
                          <div className="flex justify-between w-full">
                            <label className="font-normal text-lg flex items-center">
                              <input
                                type="radio"
                                name="pricing"
                                value="singleUser"
                                style={{
                                  width: "20px", // Increase size
                                  height: "20px", // Increase size
                                  accentColor: "#1A2B6D", // Set color for modern browsers
                                  cursor: "pointer",
                                }}
                                className="mr-2"
                              />
                              Single User
                            </label>
                            <p className="font-bold text-lg">$4350</p>
                          </div>
                        </div>
                        <div className="flex justify-between items-center mb-4">
                          <div className="flex justify-between w-full">
                            <label className="font-normal text-lg flex items-center">
                              <input
                                type="radio"
                                name="pricing"
                                value="multiUser"
                                style={{
                                  width: "20px", // Increase size
                                  height: "20px", // Increase size
                                  accentColor: "#1A2B6D", // Set color for modern browsers
                                  cursor: "pointer",
                                }}
                                className="mr-2"
                              />
                              Multi User
                            </label>
                            <p className="font-bold text-lg">$5500</p>
                          </div>
                        </div>
                        <div className="flex justify-between items-center mb-4">
                          <div className="flex justify-between w-full">
                            <label className="font-normal text-lg flex items-center">
                              <input
                                type="radio"
                                name="pricing"
                                value="enterprise"
                                style={{
                                  width: "20px", // Increase size
                                  height: "20px", // Increase size
                                  accentColor: "#1A2B6D", // Set color for modern browsers
                                  cursor: "pointer",
                                }}
                                className="mr-2"
                              />
                              Enterprise
                            </label>
                            <p className="font-bold text-lg">$6500</p>
                          </div>
                        </div>
                        <div className="flex justify-center items-center">
                          <button
                            type="button"
                            className="text-white bg-blue-700 hover:bg-navyblue focus:ring-4
                      font-medium rounded-lg text-sm px-5 py-3 me-2 mb-2 bg-[#1A2B6D] focus:outline-none
                      "
                          >
                            Buy Now
                          </button>
                        </div>
                      </div>
                    </div> */}
                    <div className="rounded overflow-hidden shadow-lg border border-offwhite bg-[#F5F5F5]">
                      <div className="p-5">
                        <p className="font-semibold text-base lg:text-lg text-[#1C77C3]">
                          RELATED TOPICS
                        </p>
                        <p className="mb-2 font-normal lg:font-medium border-b cursor-pointer text-sm lg:text-base">
                          Indigenous weapons/equipment
                        </p>
                        <p className="mb-2 font-normal lg:font-medium border-b cursor-pointer text-sm lg:text-base">
                          Advanced Short Range
                        </p>
                        <p className="mb-2 font-normal lg:font-medium border-b cursor-pointer text-sm lg:text-base">
                          F-35 jets
                        </p>
                        <p className="mb-2 font-normal lg:font-medium border-b cursor-pointer text-sm lg:text-base">
                          Defense Research and Development Organisation
                        </p>
                        <p className="mb-2 font-normal lg:font-medium border-b cursor-pointer text-sm lg:text-base">
                          Next-Generation Destroyer (NGD)
                        </p>
                        <p className="mb-2 font-normal lg:font-medium border-b cursor-pointer text-sm lg:text-base">
                          IAF Collaborates
                        </p>
                      </div>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle className="lg:font-bold text-center font-medium text-sm">
          {modalContent.title}
        </DialogTitle>
        <DialogContent className="lg:space-y-4 space-y-1">
          <p className="text-xs lg:text-base text-center">
            {modalContent.description}
          </p>

          <div className="lg:space-y-4 space-y-1">
            <TextField
              fullWidth
              size={isLargeScreen ? "medium" : "small"}
              label="Your Name"
              name="clientName"
              variant="outlined"
              value={formData.clientName}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              size={isLargeScreen ? "medium" : "small"}
              label="Company"
              name="company"
              variant="outlined"
              value={formData.company}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              size={isLargeScreen ? "medium" : "small"}
              label="Job Title"
              name="jobTitle"
              variant="outlined"
              value={formData.jobTitle}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              size={isLargeScreen ? "medium" : "small"}
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              size={isLargeScreen ? "medium" : "small"}
              label="Phone Number"
              name="phoneNumber"
              variant="outlined"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            {(modalContent.title === "Inquire Now" ||
              modalContent.title === "Speak To An Analyst") && (
              <TextField
                label="Your Query"
                name="query"
                size={isLargeScreen ? "medium" : "small"}
                value={formData.query}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                multiline
                rows={4}
              />
            )}
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} className="text-gray-600" size="small">
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            disabled={loading}
            onClick={() => {
              if (
                modalContent.title === "Inquire Now" ||
                modalContent.title === "Speak To An Analyst"
              ) {
                sendEmail("inquire");
              } else if (
                modalContent.title === "Request for Quote" ||
                modalContent.title === "Request Sample"
              ) {
                sendEmail("quote");
              }
            }}
            className="bg-blue-600 hover:bg-blue-700"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DetailedArticle;
