import React from "react";

const AboutUs = () => {
  return (
    <div
      className="flex flex-col"
      style={{ background: "linear-gradient(#1a2c66, #131c44)" }}
    >
      <section>
        <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 className="mb-4 text-2xl tracking-tight font-extrabold text-white">
              Driving Innovation in Aerospace and Defence
            </h2>
            <p className="mb-4 text-offwhite">
              At Andtelligence, we are a Microsoft for Startups and Startup
              India recognized company dedicated to developing cutting-edge AI
              solutions that enhance operational efficiency in the Aerospace and
              Defence industry. Our innovative technology streamlines technical
              documentation, ensures regulatory compliance, and facilitates
              comprehensive market research, empowering organizations to
              optimize their processes and stay ahead in a rapidly evolving
              landscape.
            </p>
            <p className="mb-4 text-offwhite">
              With a commitment to excellence and a passion for innovation, we
              are transforming how businesses operate in this critical sector.
            </p>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-8">
            <img
              className="w-full rounded-lg"
              src="/about-1.jpg"
              alt="about-us-image"
            />
            <img
              className="mt-4 w-full lg:mt-10 rounded-lg"
              src="/about-2.jpg"
              alt="about-us-image"
            />
          </div>
        </div>
      </section>
      <section>
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
          <div className="mx-auto mb-8 max-w-screen-sm lg:mb-16">
            <h2 className="mb-4 text-2xl tracking-tight font-extrabold text-white">
              Our Team
            </h2>
            <p className="font-light sm:text-xl text-offwhite">
              Meet the visionaries behind our success! Together, we are
              committed to turning innovative ideas into reality.
            </p>
          </div>
          <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <div className="text-center">
              <img
                className="mx-auto mb-4 w-36 h-36 rounded-full object-cover"
                src="/abh.jpeg"
                alt="team-image"
              />
              <h3 className="mb-1 text-2xl font-semibold tracking-tight text-white">
                <a href="#">Abhranil Chatterjee</a>
              </h3>
              <p className="text-offwhite">Founder and Director</p>
            </div>
            <div className="text-center">
              <img
                className="mx-auto mb-4 w-36 h-36 rounded-full object-cover"
                src="/anuj.jpeg"
                alt="team-image"
              />
              <h3 className="mb-1 text-2xl font-semibold tracking-tight text-white">
                <a href="#">Anuj Khatri</a>
              </h3>
              <p className="text-offwhite">Aviation and MRO specialist</p>
            </div>
            <div className="text-center">
              <img
                className="mx-auto mb-4 w-36 h-36 rounded-full object-cover"
                src="/user.jpg"
                alt="team-image"
              />
              <h3 className="mb-1 text-2xl font-semibold tracking-tight text-white">
                <a href="#">Ankush G.</a>
              </h3>
              <p className="text-offwhite">Software Developer</p>
            </div>
            <div className="text-center">
              <img
                className="mx-auto mb-4 w-36 h-36 rounded-full object-cover"
                src="/user.jpg"
                alt="team-image"
              />
              <h3 className="mb-1 text-2xl font-semibold tracking-tight text-white">
                <a href="#">Sameer P.</a>
              </h3>
              <p className="text-offwhite">Software Developer</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
