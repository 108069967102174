import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import API_BASE_URL from "../utils";
import Loader from "../Loader";

interface CardProps {
  image: string;
  heading_2: string;
  query_name: string;
  document_id: string | number;
}

const Card: React.FC<CardProps> = ({
  image,
  heading_2,
  query_name,
  document_id,
}) => {
  const navigate = useNavigate();

  const handleReadMore = () => {
    navigate(`/article/${query_name}/${document_id}`);
  };

  return (
    <div
      className="max-w-xs bg-white border-2 border-gray-800 rounded-xl shadow h-60 overflow-hidden
            transition-transform duration-300 ease-in-out transform hover:scale-105"
    >
      <div
        className="h-full bg-cover bg-center rounded-lg"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="p-5 h-full flex flex-col justify-end bg-gradient-to-t from-black/90 via-black/50 to-transparent">
          <h5 className="mb-2 text-xl font-bold tracking-tight text-white">
            {heading_2}
          </h5>
          <button
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg border w-32"
            onClick={handleReadMore}
          >
            Read more
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

const Table = () => {
  const [cardsData, setCardsData] = useState<CardProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTrendingArticles = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}getTrendingArticles`);
        setCardsData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching trending articles:", error);
        setError("Failed to load trending articles.");
        setLoading(false);
      }
    };

    fetchTrendingArticles();
  }, []);

  return (
    <>
      <div
        className="mx-auto max-w-7xl px-6 flex flex-col justify-center items-center"
        id="exchange-section"
      >
        {loading && <Loader />}
        <div className="text-center" id="2">
          <h3 className="text-offwhite text-2xl md:text-4xl font-bold mb-3">
            Inqara Reports
          </h3>
          <p className="text-bluish md:text-base font-normal leading-8">
            Stay informed with the latest insights from our team of experts.
          </p>
        </div>
        <div
          className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-y-10 gap-x-20 mt-24"
          id="3"
        >
          {cardsData.map((card, index) => (
            <Card
              key={index}
              image={card.image}
              heading_2={card.heading_2}
              query_name={card.query_name}
              document_id={card.document_id}
            />
          ))}
        </div>
      </div>
      <div className="flex justify-center items-center">
        <img
          src="/images/Table/Untitled.svg"
          alt="ellipse"
          className="md:mb-10 md:-mt-6"
        />
      </div>
    </>
  );
};

export default Table;
