import { useState, useEffect } from "react";
import axios from "axios";
import { IconButton, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import API_BASE_URL from "../utils";
import Loader from "../Loader";

const Navbar = () => {
  const [isArticleDropdownOpen, setIsArticleDropdownOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}getCategories`);
        if (response.status === 200) {
          setCategories(response.data.data);
        } else {
          setError("Error fetching categories");
        }
      } catch (err) {
        setError("An error occurred while fetching categories");
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <nav className="bg-gradient-to-r from-[#0C0B27] via-[#1B164F] to-[#020A1E] sticky top-0 z-50">
      {loading && <Loader />}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          {/* Left side: Logo */}
          <div className="flex-shrink-0">
            <a href="/" className="cursor-pointer">
              <img
                src="/images/Logo/&telligence_dark.png"
                alt="Logo"
                className="h-12"
              />
            </a>
          </div>

          {/* Menu button for mobile */}
          <div className="block md:hidden">
            <IconButton onClick={toggleDrawer} color="inherit">
              <MenuIcon style={{ color: "white" }} />
            </IconButton>
          </div>

          {/* Right side: Navigation links */}
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4 relative">
              {/* Articles Dropdown */}
              <div
                onMouseEnter={() => setIsArticleDropdownOpen(true)}
                onMouseLeave={() => setIsArticleDropdownOpen(false)}
                className="relative"
              >
                <span className="text-white hover:bg-[#4B5563] px-3 py-2 rounded-md text-lg font-normal cursor-pointer">
                  Inqara Reports
                </span>

                {/* Dropdown */}
                {isArticleDropdownOpen && (
                  <div className="absolute right-0 top-full mt-1 w-56 bg-white shadow-lg rounded-md z-50">
                    {categories.map((article: any) => (
                      <Link
                        key={article.category_id}
                        to={`/category/${article.category_id}`}
                        className="block px-4 py-1 text-black hover:bg-[#E5E7EB] cursor-pointer text-sm"
                      >
                        <span>{article.category}</span>
                      </Link>
                    ))}
                  </div>
                )}
              </div>

              <a
                href="/about-us"
                className="text-white hover:bg-[#4B5563] px-3 py-2 rounded-md text-lg font-normal"
              >
                About Us
              </a>
              <a
                href="/contact-us"
                className="text-white hover:bg-[#4B5563] px-3 py-2 rounded-md text-lg font-normal"
              >
                Get In Touch
              </a>
              <a
                href="https://platform.andtelligence.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:bg-[#4B5563] px-3 py-2 rounded-md text-lg font-normal"
              >
                Log In
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Drawer */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            height: "100%",
            backgroundColor: "rgba(12, 11, 39, 0.9)",
            color: "white",
            overflow: "auto",
            width: "100%",
          },
        }}
      >
        <div className="flex justify-between items-center p-4 bg-gradient-to-r from-[#0C0B27] via-[#1B164F] to-[#020A1E] text-white">
          <h2 className="text-lg font-bold">Menu</h2>
          <IconButton onClick={toggleDrawer} color="inherit">
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
        </div>
        <div className="flex flex-col p-4">
          <div>
            <span
              onClick={() => setIsArticleDropdownOpen(!isArticleDropdownOpen)}
              className="text-white hover:bg-[#28508d] rounded-md cursor-pointer text-lg p-2"
            >
              Inqara Reports
            </span>

            {/* Mobile Dropdown */}
            {isArticleDropdownOpen && (
              <div className="relative">
                <div className="ml-2 mt-2 border border-gray-200 bg-white rounded-md shadow-lg relative">
                  {/* Arrow */}
                  <div className="absolute -top-2 left-2 w-4 h-4 bg-white border-l border-t border-gray-200 transform rotate-45"></div>

                  {/* Dropdown Links */}
                  {categories.map((article: any) => (
                    <Link
                      key={article.category_id}
                      to={`/category/${article.category_id}`}
                      className="block text-black cursor-pointer ml-4 mt-2 text-sm font-light"
                      onClick={toggleDrawer}
                    >
                      <span>{article.category}</span>
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
          <a
            href="/"
            className="text-white hover:bg-[#28508d] rounded-md ml-2 mt-4 text-lg"
          >
            Home
          </a>
          <a
            href="/about-us"
            className="text-white hover:bg-[#28508d] rounded-md ml-2 mt-4 text-lg"
          >
            About Us
          </a>
          <a
            href="/contact-us"
            className="text-white hover:bg-[#28508d] rounded-md ml-2 mt-4 text-lg"
          >
            Get In Touch
          </a>
          <a
            href="https://platform.andtelligence.com"
            className="text-white hover:bg-[#28508d] rounded-md ml-2 mt-4 text-lg"
          >
            Log In
          </a>
        </div>
      </Drawer>
    </nav>
  );
};

export default Navbar;
