'use client';

import React, { useState, useEffect } from 'react';

const CookieNotice: React.FC = () => {
  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true); // Track loading state

  useEffect(() => {
    // Check if cookie consent has already been accepted
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'true') {
      setIsAccepted(true);
    }
    setIsLoading(false); // Once the check is complete, set loading to false
  }, []);

  const handleAccept = () => {
    // Set consent in local storage
    localStorage.setItem('cookieConsent', 'true');
    setIsAccepted(true);
  };

  // Avoid rendering the notice until the check is done
  if (isLoading) return null;

  // Don't render the notice if consent has already been accepted
  if (isAccepted) return null;

  return (
    <section className="z-50 opacity-80 fixed max-w-xl p-4 mx-auto bg-white border border-gray-200 left-12 bottom-16 rounded-2xl">
      <h2 className="font-semibold text-gray-800 text-2xl">🍪 Cookie Notice</h2>
      
      <p className="mt-4 text-base text-gray-600">
        We use cookies to ensure that we give you the best experience on our website.
      </p>
      
      <div className="flex items-center justify-between mt-4 gap-x-4 shrink-0">
        <button 
          className="text-sm text-gray-800 underline transition-colors duration-300 hover:text-gray-600 focus:outline-none">
          View Privacy Policy
        </button>
        
        <button 
          onClick={handleAccept}
          className="text-sm text-black bg-gray-300 font-medium rounded-lg px-4 py-2.5 duration-300 transition-colors border hover:bg-green">
          Accept
        </button>
      </div>
    </section>
  );
};

export default CookieNotice;
