const workdata = [
    {
        imgSrc: '/images/venture.png',
        heading: 'VC Funds and Investors',
        subheading: 'Create investment theses, conduct commercial due diligence, and identify lucrative investment opportunities.',
    },
    {
        imgSrc: '/images/analysis.png',
        heading: 'Analysts',
        subheading: 'Understand potential contracts in the pipeline, forecast order books, and evaluate market trends.',
    },
    {
        imgSrc: '/images/industry.png',
        heading: 'Industry Professionals',
        subheading: 'Gain insights into market size, identify pockets of opportunities, and develop strategic plans.',
    },
    {
        imgSrc: '/images/consulting.png',
        heading: 'Business Consultants',
        subheading: 'Access comprehensive market research to advise clients effectively and develop data-backed strategies.',
    },
    {
        imgSrc: '/images/government.png',
        heading: 'Government Agencies',
        subheading: 'Utilize precise data and forecasts for policy-making and defense planning.',
    },
    {
        imgSrc: '/images/manufacturing.png',
        heading: 'Manufacturers',
        subheading: 'Understand the competitive landscape, assess demand trends, and optimize supply chain strategies.',
    },
];

const Work = () => {
    return (
        <div>
            <div className='mx-auto max-w-7xl mt-16 px-6 mb-20 relative'>
                <div className="radial-bgone hidden lg:block"></div>
                <div className='text-center mb-14'>
                    <h3 className='text-offwhite text-2xl md:text-4xl font-bold mb-3'>Who We Empower</h3>
                    <p className='text-bluish md:text-base font-normal leading-8'>At &telligence, we cater to a diverse range of users within the Aerospace and Defence sector, offering tailored insights and data-driven solutions.</p>
                </div>

                <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-y-12 gap-x-12 mt-24'>
                    {workdata.map((item, index) => (
                        <div className='card-b p-8' key={index}>
                            <div className='flex items-center justify-center'>
                                <img src={item.imgSrc} alt={item.heading} width={44} height={44} />
                            </div>
                            <h3 className='text-xl lg:text-2xl text-white font-semibold text-center mt-8'>{item.heading}</h3>
                            <p className='text-base font-normal text-offwhite text-center mt-2'>{item.subheading}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Work;
