import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import Loader from "../Loader";
import API_BASE_URL from "../utils";

const Simple = () => {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoader, setLoader] = useState<boolean>(false);

  const blockedDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "aol.com",
    "icloud.com",
    "rediffmail.com",
    "protonmail.com",
    "live.com",
];

const isCompanyEmail = (email: string) => {
    const domain = email.split('@')[1]?.toLowerCase();
    if (!domain) return false;
    return !blockedDomains.includes(domain);
};

  const handleAddToWaitlist = async () => {
    setLoader(true);
    if (!email || !email.includes("@") || !email.includes(".")) {
      setError("Please enter a valid email.");
      setMessage("");
      setLoader(false);
      return;
    }

    if (!isCompanyEmail(email)) {
        setError("Only company emails are allowed.");
        setMessage("");
        setLoader(false);
        return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}addToWaitlist`,
        { email }
      );

      if (response.data.message === "Already subscribed") {
        setError("This email is already subscribed.");
        setMessage("");
      } else {
        setMessage(
          "Your email has been added to our waiting list. You can unsubscribe anytime."
        );
        setError("");
        setEmail("");
      }
      setLoader(false);
    } catch (error) {
      setError("An error occurred. Please try again later.");
      setMessage("");
      setLoader(false);
    }
  };

  return (
    <div className="simple-bg relative">
      {isLoader && <Loader />}
      <div className="simpleone"></div>
      {/* <div className="simpletwo"></div> */}
      <div className="simplethree"></div>
      <div className="simplefour"></div>
      <div className="simplefive"></div>
      <div className="mx-auto max-w-5xl py-24 px-6">
        <h3 className="text-center text-offwhite text-3xl lg:text-5xl font-semibold mb-6">
          Join the waitlist
        </h3>
        <p className="text-center text-bluish text-xl font-normal mb-8">
          Sign up for the latest updates & to join the waitlist for early
          access.
        </p>
        <p className="text-center text-red text-base font-normal mb-5">
          {error}
        </p>
        <div className="flex justify-center">
          <TextField
            id="outlined-basic"
            placeholder="Enter your email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              background: "white",
              borderRadius: "10px",
              marginRight: "10px",
            }}
          />
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            color="success"
            style={{ backgroundColor: "purple" }}
            onClick={handleAddToWaitlist}
          >
            Send
          </Button>
        </div>
        <p className="text-center text-green text-base font-normal mt-5">
          {message}
        </p>
      </div>
      <div className="simplesix"></div>
      {/* <div className="simpleseven"></div> */}
      <div className="simpleeight"></div>
      <div className="simplenine"></div>
      <div className="simpleten"></div>
    </div>
  );
};

export default Simple;
